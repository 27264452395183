// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  env: 'qa-02',
  host: 'mkpqa.com',

  hedwigUrl: 'https://hedwig.mkpqa.com',
  saharaUrl: 'https://my.mkpqa.com',
  instructorMarketUrl: 'https://instructors.mkpqa.com',
  serviceMarketUrl: 'https://instructors.mkpqa.com',
  integrationFramework: 'https://integration.mkpqa.com',
  amberBaseUrl: 'https://amber.mkpqa.com',
  amberUrl: 'https://amber.mkpqa.com/api',
  bouncerUrl: 'https://account.mkpqa.com',
  sphinxUrl: 'https://downloads.mkpqa.com',

  welcomePageCampaignKey: 'product-marketplace.welcome-page.campaign',
  welcomePagePromotionKey: 'product-marketplace.welcome-page.promotion',

  aws: {
    key: 'AKIAQPD22X7YXQMQJEEZ',
    s3Url: 'https://downloads.mkpqa.com/proxy/service/jwt',
    cdnBucket: 'leap-qa-02-cdn',
    uploadsBucket: 'leap-qa-02-product-marketplace-uploads',
  },

  adyen: {
    clientKey: 'test_EMPSXSACJZFL3LBMXZFT323B2M4UWAPD'
  },
  
  okta: {
    issuer: 'https://dev-3710614.okta.com/oauth2/default',
    clientId: '0oag85ud09GPYD9PD5d7',
    prefix: 'mkpqa'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
